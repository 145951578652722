import { Easing } from "remotion";

export const constants = {
  PROJECT_NAME: "paracast.io",
  PROJECT_NAME_CAPITALISED: "Paracast.io",
  PROJECT_HOME_URL: "https://paracast.io",
  PROJECT_BASIC_LOGO_URL:
    "https://public-assets.paracast.io/paracast-org_logo.png",
  FREE_PLAN_TITLE: "free",
  MARSX_PROJECTS: ['paracast.io', 'unicornplatform.com', 'seobotai.com', 'filmgrail.com']
};