import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useDisclosure } from '@mantine/hooks';
import { NewVideoModal } from './NewVideoModal';

export function CreateVideoButton() {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button
        variant="filled"
        size="sm"
        rightSection={<IconPlus size="1rem" />}
        onClick={open}
      >
        Create video
      </Button>
      <NewVideoModal opened={opened} onClose={close} />
    </>
  );
}
