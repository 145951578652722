import React, { useState, useRef } from "react";
import {
  Box, Button, ActionIcon, Group, Progress, Paper, ScrollArea, TextInput, Stack, Popover
} from "@mantine/core";
import { IconPlus, IconTrash, IconUpload, IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useFetcher } from "@remix-run/react";
import { useEditorState } from "../../../AppContent/state/EditorStateContext";
import { handleFileChange } from "../handleFileChange";
import { handleUpload } from "../handleUpload";

interface TextWithImageItem {
  text: string;
  image: string;
}

interface TextWithImageListEditorProps {
  content: {
    type: "list";
    value: TextWithImageItem[];
  };
  isEditable: boolean;
  handleContentChange: (newValue: TextWithImageItem[]) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string;
}

export function TextWithImageListEditor({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
}: TextWithImageListEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState<TextWithImageItem[]>(content.value);
  const [uploadingIndex, setUploadingIndex] = useState<number | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const { editorState } = useEditorState();

  const handleTextChange = (index: number, value: string) => {
    const updatedItems = [...listItems];
    updatedItems[index] = { ...updatedItems[index], text: value };
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleUploadComplete = async (data: any, index: number) => {
    if (data.success) {
      await handleUpload(data, fileInputRef, setUploadProgress, (publicUrl) => {
        const updatedItems = [...listItems];
        updatedItems[index] = { ...updatedItems[index], image: publicUrl };
        setListItems(updatedItems);
        handleContentChange(updatedItems);
        setUploadingIndex(null);
        setUploadProgress(0);
      });
    }
  };

  const handleAddItem = () => {
    setListItems([...listItems, { text: "", image: "" }]);
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = listItems.filter((_, i) => i !== index);
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadingIndex(index);
      handleFileChange({
        file,
        uploadType: "image",
        editorState,
        setUploadProgress,
        fetcher
      });
    }
  };

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      onChange={setIsOpen}
      width={400}
      position="left"
      offset={20}
      withArrow
    >
      <Popover.Target>
        <Box onClick={() => setIsOpen((o) => !o)} style={style}>
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea.Autosize mah={800}>
          {listItems.map((item, index) => (
            <Box key={index} mb="xl">
              <Stack spacing="xs">
                <TextInput
                  value={item.text}
                  onChange={(e) => handleTextChange(index, e.target.value)}
                  placeholder={t("Enter text")}
                />
                {item.image ? (
                  <Paper p="sm" style={{ textAlign: "center" }}>
                    <img 
                      src={item.image} 
                      alt={item.text} 
                      style={{ maxHeight: "100px" }} 
                    />
                  </Paper>
                ) : (
                  <Button
                    onClick={() => fileInputRef.current?.click()}
                    leftSection={<IconUpload size="1rem" />}
                    variant="light"
                  >
                    {t("Upload image")}
                  </Button>
                )}
                {uploadingIndex === index && (
                  <Progress value={uploadProgress} size="sm" />
                )}
                <ActionIcon
                  variant="light"
                  color="red"
                  onClick={() => handleRemoveItem(index)}
                >
                  <IconTrash size="1rem" />
                </ActionIcon>
              </Stack>
            </Box>
          ))}
          <Button
            variant="light"
            leftSection={<IconPlus size="1rem" />}
            onClick={handleAddItem}
            fullWidth
          >
            {t("Add item")}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(e) => onFileChange(e, listItems.length - 1)}
          />
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
} 