import React, { useState } from "react";
import {
  Box, Button, ActionIcon, Group, TextInput, Stack, ScrollArea, Popover
} from "@mantine/core";
import { IconPlus, IconTrash, IconArrowUp, IconArrowDown } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface TextOnlyItem {
  text: string;
}

interface TextOnlyListEditorProps {
  content: {
    type: "list";
    value: TextOnlyItem[];
  };
  isEditable: boolean;
  handleContentChange: (newValue: TextOnlyItem[]) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string;
}

export function TextOnlyListEditor({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
}: TextOnlyListEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState<TextOnlyItem[]>(content.value);
  const { t } = useTranslation();

  const handleTextChange = (index: number, value: string) => {
    const updatedItems = [...listItems];
    updatedItems[index] = { text: value };
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleAddItem = () => {
    setListItems([...listItems, { text: "" }]);
    handleContentChange([...listItems, { text: "" }]);
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = listItems.filter((_, i) => i !== index);
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleMoveItem = (index: number, direction: "up" | "down") => {
    const updatedItems = [...listItems];
    if (direction === "up" && index > 0) {
      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
    } else if (direction === "down" && index < updatedItems.length - 1) {
      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
    }
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      onChange={setIsOpen}
      width={400}
      position="left"
      offset={20}
      withArrow
    >
      <Popover.Target>
        <Box className="frame__editable_content" onClick={() => setIsOpen((o) => !o)} style={style}>
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea.Autosize mah={800}>
          {listItems.map((item, index) => (
            <Box key={index} mb="sm">
              <Group align="flex-start" wrap="nowrap">
                <TextInput
                  style={{ flex: 1}}
                  classNames={{ input: "frame__editable_content_text_input" }}
                  value={item.text}
                  onChange={(e) => handleTextChange(index, e.target.value)}
                  placeholder={t("Enter text")}
                  rightSection={
                      <ActionIcon.Group style={{ position: "relative", right: "11px" }}>
                        <ActionIcon
                        variant="transparent"
                        color="gray"
                        onClick={() => handleMoveItem(index, "up")}
                        disabled={index === 0}
                        title={t("Move up")}
                      >
                        <IconArrowUp size="1rem" />
                      </ActionIcon>
                      <ActionIcon
                        variant="transparent"
                        color="gray"
                        onClick={() => handleMoveItem(index, "down")}
                        disabled={index === listItems.length - 1}
                        title={t("Move down")}
                      >
                        <IconArrowDown size="1rem" />
                        </ActionIcon>
                      </ActionIcon.Group>
                  }
                />
                <ActionIcon
                  variant="light"
                  color="red"
                  onClick={() => handleRemoveItem(index)}
                  title={t("Remove item")}
                >
                  <IconTrash size="1rem" />
                </ActionIcon>
              </Group>
            </Box>
          ))}
          <Button
            variant="light"
            leftSection={<IconPlus size="1rem" />}
            onClick={handleAddItem}
            fullWidth
            mt="md"
          >
            {t("Add item")}
          </Button>
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
} 