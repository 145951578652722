import {
  AppShellFooter,
  Container,
  Group,
  Text,
  Stack,
  ActionIcon,
  Menu,
  Divider,
  Badge,
} from "@mantine/core";
import {
  IconBrandX,
  IconBrandLinkedin,
  IconMail,
  IconBrandYoutube,
  IconBrandDiscord,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useMatches } from "@remix-run/react";
import { checkIsOnEditorPage } from "~/utils/checkIsOnEditorPage";

export default function AppFooter() {
  const { t } = useTranslation();
  // Use useMatches to get the current route's loader data
  const matches = useMatches();
  const { isOnEditorPage } = checkIsOnEditorPage(matches);

  const socialIcons = [
    { icon: IconBrandX, link: "https://x.com/paracast_io" },
    {
      icon: IconBrandLinkedin,
      link: "https://www.linkedin.com/company/paracast/",
    },
    { icon: IconMail, link: "mailto:hi@paracast.io" },
  ];

  const founderIcons = [
    { icon: IconBrandX, link: "https://x.com/alexanderisorax" },
    { icon: IconBrandLinkedin, link: "https://linkedin.com/in/alexanderisora" },
    { icon: IconBrandYoutube, link: "https://youtube.com/@alexanderisora" },
    { icon: IconMail, link: "https://10k.isora.me/" },
  ];

  if (isOnEditorPage) {
    return null;
  } else {
    return (
      <div>
        <Divider my="md" />
        <Container>
          <Group justify="space-between" align="flex-start" pt="lg" pb="lg">
            <Stack>
              <Text fw={700}>{t("resources")}</Text>
              <Text component="a" href="https://www.paracast.io/" target="_blank" c="dimmed">
                {"Home page"}
              </Text>
              <Text component="a" href="https://www.paracast.io/changelog/" target="_blank" c="dimmed">
                {"Changelog"}
              </Text>
              <Group gap="xs" wrap="nowrap">
                <Text style={{ position: 'relative'}} component="a" href="https://www.paracast.io/community/" target="_blank" c="dimmed">
                  {"Community"}
                  {<Badge rightSection={<IconBrandDiscord size={14}/>} style={{ position: 'absolute', top: '-3px', right: '-4px', transform: 'translateX(100%)'}} variant="dot" color="lime">Join</Badge>} 
                </Text>
              </Group>
            </Stack>

            <Stack>
              <Text fw={700}>{"Legal"}</Text>
              <Text component="a" target="_blank" href="https://www.paracast.io/terms/" c="dimmed">
                 Terms of service
              </Text>
              <Text component="a" target="_blank" href="https://www.paracast.io/refund-policy/" c="dimmed">
                Refund Policy
              </Text>
              <Text component="a" target="_blank" href="https://www.paracast.io/privacy/" c="dimmed">
                Privacy Policy
              </Text>
            </Stack>

            <Stack>
              <Text fw={700}>{t("the_project")}</Text>
              <Group gap="xs">
                {socialIcons.map((item, index) => (
                  <ActionIcon
                    key={index}
                    size="lg"
                    variant="subtle"
                    component="a"
                    href={item.link}
                    target="_blank"
                  >
                    <item.icon size={18} />
                  </ActionIcon>
                ))}
              </Group>
              <Text fw={700}>{t("the_founder")}</Text>
              <Group gap="xs">
                {founderIcons.map((item, index) => (
                  <ActionIcon
                    key={index}
                    size="lg"
                    variant="subtle"
                    component="a"
                    href={item.link}
                    target="_blank"
                  >
                    <item.icon size={18} />
                  </ActionIcon>
                ))}
              </Group>
            </Stack>
          </Group>
        </Container>
      </div>
    );
  }
}
