import React, { useMemo } from "react";
import { AbsoluteFill, interpolate, spring } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { easeInOutExpo, FPS } from "../../../remotionConstant";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { SyntaxHighlightedCode } from "./SyntaxHighlightedCode";
import { createAnimatedCircles } from "./AnimatedCircle";

export const FloatingCodeCard: React.FC<{
  content: any;
  displayAnimations: boolean;
  isEditable: boolean;
  currentRemotionFrame: number;
  durationInFrames: number;
  editorState: any;
}> = ({
  content,
  displayAnimations,
  isEditable,
  currentRemotionFrame,
  durationInFrames,
  editorState,
}) => {
  const frame = currentRemotionFrame;

  // Animation timing control
  const introDuration = 60;
  const outroDuration = 30;
  const steadyStateDuration = durationInFrames - (introDuration + outroDuration);
  
  // Calculate timing points
  const introEndFrame = introDuration;
  const steadyStateEndFrame = introEndFrame + steadyStateDuration;

  const y = interpolate(
    frame,
    [0, introEndFrame, steadyStateEndFrame, durationInFrames],
    [-100, 0, 0, 0],
    {
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );

  const x = interpolate(
    frame,
    [0, steadyStateEndFrame, durationInFrames],
    [0, 0, -100],
    {
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );

  const scale = interpolate(
    frame,
    [0, introEndFrame, steadyStateEndFrame],
    [0.8, 1, 1],
    {
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );
  const opacity = interpolate(
    frame,
    [0, durationInFrames - outroDuration, durationInFrames],
    [1, 1, 0],
    {
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );
  const rotation = interpolate(frame, [0, durationInFrames], [-3, -3], {
    extrapolateRight: "clamp",
  });

  const defaultCode = `function animateCode(progress) {
    const frames = 60;
    const step = Math.floor(progress * frames);
    for (let i = 0; i < step; i++) {
      renderFrame(i);
    }
    return "Animation complete!";
  }`;

  const enterBorderAnimationDelay = 40;
  const enterBorderAnimation = spring({
    fps: FPS,
    frame: currentRemotionFrame - enterBorderAnimationDelay,
    config: { damping: 100, stiffness: 200, mass: 0.5 },
  });

  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );
  const commonStyles = getCommonStyles(editorState);
  const shadowOpacity = interpolate(enterBorderAnimation, [0, 1], [0, 1]);
  const shadowSize = interpolate(enterBorderAnimation, [0, 1], [0, 14]);
  const code = content.code?.value || defaultCode;

  const cardStyle: React.CSSProperties = useMemo(
    () => ({
      background: bgColorObject.backgroundColor,
      borderRadius: "0.5em",
      padding: "1em",
      transform: displayAnimations
        ? `translateX(${x}%) translateY(${y}px) scale(${scale}) rotate(${rotation}deg) perspective(800px) rotateX(2deg)`
        : "rotate(-3deg) perspective(800px) rotateX(2deg)",
      transformStyle: "preserve-3d",
      // transition: "transform 0.5s ease-out",
      width: "80%",
      position: "relative",
      zIndex: 5,
      overflow: "visible",
      border: "0.25em solid",
      borderColor: commonStyles.color,
      boxShadow: `${-shadowSize}px ${shadowSize}px 0 rgba(${
        commonStyles.color === "white" ? "255, 255, 255" : "0, 0, 0"
      }, ${shadowOpacity})`,
      opacity: displayAnimations ? opacity : 1,

    }),
    [displayAnimations, x, y, scale, rotation, shadowSize, shadowOpacity, commonStyles.color, bgColorObject.backgroundColor, opacity]
  );

  const containerStyle = useMemo(
    () => ({
      fontFamily: commonStyles.fontFamily,
      color: commonStyles.color,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: bgColorObject["backgroundColor"],
    }),
    [commonStyles, bgColorObject]
  );

  const codeContainerStyle: React.CSSProperties = useMemo(
    () => ({
      width: "100%",
      overflow: "visible",
      maxHeight: "none",
      borderRadius: "4px",
      padding: "0.5em",
    }),
    []
  );

  // const codeKey = useMemo(() => `code-${code.length}-${Date.now()}`, [code]);
  const codeKey = useMemo(() => `code-${code.length}`, [code]);
  
  return (
    <AbsoluteFill style={containerStyle}>
      {createAnimatedCircles(
        displayAnimations,
        currentRemotionFrame,
        editorState.styles.accentColor,
        durationInFrames
      )}
      <div style={cardStyle}>
        <div style={codeContainerStyle}>
          <Editable
            isEditable={isEditable}
            content={content.code}
            hint="Enter your code here"
            offset={-50}
            className="frame__editable_content--normal-size"
          >
            <SyntaxHighlightedCode 
              key={codeKey}
              code={code}
              isEditable={isEditable}
              content={content}
              bgColorObject={bgColorObject}
              displayAnimations={displayAnimations}
              currentRemotionFrame={currentRemotionFrame}
              editorState={editorState}
            />
          </Editable>
        </div>
      </div>
    </AbsoluteFill>
  );
};