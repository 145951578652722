import React, { useState, useRef, useEffect } from "react";
import {
  Box, Button, ActionIcon, Group, Progress, Paper, ScrollArea, Popover, Text, Stack,
  Flex
} from "@mantine/core";
import { IconPlus, IconTrash, IconUpload, IconArrowUp, IconArrowDown, IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useFetcher } from "@remix-run/react";
import { useEditorState } from "../../../AppContent/state/EditorStateContext";
import { handleFileChange } from "../handleFileChange";
import { handleUpload } from "../handleUpload";

interface ImageListItem {
  image: string;
}

interface ImageListEditorProps {
  content: {
    listType: "list-image-only";
    value: ImageListItem[];
  };
  isEditable: boolean;
  handleContentChange: (newValue: ImageListItem[]) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string;
}

export function ImageListEditor({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
}: ImageListEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState<ImageListItem[]>(content.value);
  const [uploadingIndex, setUploadingIndex] = useState<number | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [hoveredImageIndex, setHoveredImageIndex] = useState<number | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const { editorState } = useEditorState();

  useEffect(() => {
    if (fetcher.data && fetcher.state === "idle" && uploadingIndex !== null) {
      handleUploadComplete(fetcher.data, uploadingIndex);
    }
  }, [fetcher]);

  const handleUploadComplete = async (data: any, index: number) => {
    if (data.success) {
      await handleUpload(data, fileInputRef, setUploadProgress, (publicUrl) => {
        const updatedItems = [...listItems];
        updatedItems[index] = { image: publicUrl };
        setListItems(updatedItems);
        handleContentChange(updatedItems);
        setUploadingIndex(null);
        setUploadProgress(0);
      });
    } else {
      console.error("Failed to get signed URL");
    }
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadingIndex(index);
      handleFileChange({
        file,
        uploadType: "image",
        editorState,
        setUploadProgress,
        fetcher
      });
    }
  };

  const handleAddItem = () => {
    const newItem = { image: "" };
    setListItems([...listItems, newItem]);
    handleContentChange([...listItems, newItem]);
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = listItems.filter((_, i) => i !== index);
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleMoveItem = (index: number, direction: "up" | "down") => {
    const updatedItems = [...listItems];
    if (direction === "up" && index > 0) {
      [updatedItems[index - 1], updatedItems[index]] = [updatedItems[index], updatedItems[index - 1]];
    //   if (uploadingIndex === index) setUploadingIndex(index - 1);
    //   if (uploadingIndex === index - 1) setUploadingIndex(index);
    } else if (direction === "down" && index < updatedItems.length - 1) {
      [updatedItems[index], updatedItems[index + 1]] = [updatedItems[index + 1], updatedItems[index]];
    //   if (uploadingIndex === index) setUploadingIndex(index + 1);
    //   if (uploadingIndex === index + 1) setUploadingIndex(index);
    }
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const initiateUpload = (index: number) => {
    if (fileInputRef.current) {
      fileInputRef.current.setAttribute('data-index', index.toString());
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = (index: number, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent triggering the file upload dialog
    const updatedItems = [...listItems];
    updatedItems[index] = { image: "" };
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      onChange={setIsOpen}
      width={400}
      position="left"
      offset={20}
      withArrow
      transitionProps={{ duration: 0 }}
    >
      <Popover.Target>
        <Box
          title={isEditable ? t("Edit list") : ""}
          className="frame__editable_content"
          onClick={() => setIsOpen((o) => !o)}
          style={style}
        >
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea.Autosize offsetScrollbars mah={800}>
          {hint && (
            <Text size="sm" c="dimmed" mb="xs">
              {hint}
            </Text>
          )}
          {listItems.map((item, index) => (
            <Box key={index} mb="xl">
              <Group align="flex-start" wrap="nowrap">
                <Stack style={{ flex: 1 }} gap="xs" className="list-text-with-image-item">
                  {item.image ? (
                    <Box
                      mt="xs"
                      style={{ position: 'relative' }}
                      onMouseEnter={() => setHoveredImageIndex(index)}
                      onMouseLeave={() => setHoveredImageIndex(null)}
                    >
                      <Paper
                        p="sm"
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                          overflow: 'hidden',
                          borderRadius: '4px',
                          textAlign: "center"
                        }}
                        onClick={() => initiateUpload(index)}
                      >
                        <img
                          src={item.image}
                          alt=""
                          style={{
                            display: 'block',
                            margin: "auto",
                            maxHeight: "50px",
                          }}
                        />
                        {hoveredImageIndex === index && (
                          <>
                            <Box
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(0, 0, 0, 0.4)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <IconUpload size="2rem" color="white" />
                            </Box>
                            <ActionIcon
                              variant="light"
                              color="red"
                              style={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                zIndex: 2
                              }}
                              onClick={(e) => handleRemoveImage(index, e)}
                              title={t("Remove image")}
                            >
                              <IconX size="1rem" />
                            </ActionIcon>
                          </>
                        )}
                      </Paper>
                    </Box>
                  ) : (
                    <Button
                      onClick={() => initiateUpload(index)}
                      leftSection={<IconUpload size="1rem" />}
                      variant="transparent"
                      size="compact-sm"
                      pt="xs"
                      disabled={uploadingIndex !== null} // Disable button if an upload is in progress

                    >
                      {t("Click to upload")}
                    </Button>
                  )}
                  {uploadingIndex === index && (
                    <Progress value={uploadProgress} size="sm" />
                  )}
                </Stack>
                {/* <ActionIcon.Group> */}
                <Flex
                  justify="flex-start"
                  align="flex-start"
                  direction="column"
                  wrap="wrap"
                  mih={50}

                >
                    <ActionIcon
                      variant="subtle"
                      color="gray"
                      onClick={() => handleMoveItem(index, "up")}
                      disabled={index === 0}
                      title={t("Move up")}
                    >
                      <IconArrowUp size="1rem" />
                    </ActionIcon>
                    <ActionIcon
                      variant="subtle"
                      color="gray"
                      onClick={() => handleMoveItem(index, "down")}
                      disabled={index === listItems.length - 1}
                      title={t("Move down")}
                    >
                      <IconArrowDown size="1rem" />
                    </ActionIcon>
                  
                </Flex>
                  {/* </ActionIcon.Group> */}
                <ActionIcon
                    variant="light"
                    color="red"
                    onClick={() => handleRemoveItem(index)}
                    title={t("Remove item")}
                    >
                    <IconTrash size="1rem" />
                  </ActionIcon>
              </Group>
            </Box>
          ))}
          <Button
            variant="light"
            leftSection={<IconPlus size="1rem" />}
            onClick={handleAddItem}
            fullWidth
            mt="md"
          >
            {t("Add image")}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(e) => {
              const index = parseInt(fileInputRef.current?.getAttribute('data-index') || '0', 10);
              onFileChange(e, index);
            }}
          />
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
} 