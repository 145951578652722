// app/components/remotionHelpers/Frames/EditableFrameWrapper.tsx

import React, { useState, useContext, useCallback } from "react";
import { useEditorState } from "../../AppContent/state/EditorContext";
import pkg from "lodash";
import { FrameTextEditor } from "./FrameTextEditor";
import { FrameImageAndVideoUploader } from "./FrameImageAndVideoUploader";
import { ListEditorFactory } from "./Lists/ListEditorFactory";
import { FrameListEditor } from "./FrameListEditor";

const { debounce, isEqual } = pkg;

interface EditableProps {
  content: {
    type: string;
    value: string;
  };
  children: React.ReactNode;
  style?: any;
  offset?: number;
  hint?: string | undefined;
  isEditable: boolean;
  className?: string;
}

const FrameDataContext = React.createContext<any>(null);

export const Editable: React.FC<EditableProps> = ({
  content,
  children,
  style,
  hint,
  offset = 20,
  isEditable,
  className,
}) => {
  // if not editable, just show the content of a frame, without the editing UI wrappers
  // we need it in 2 places: for the timeline previews, and MOST IMPORTANT, for the rendering on AWS lambda 
  // (aws lambda does not know about remix, state, mantine and other app-only stuff, only about remotion components!)
  if (!isEditable) {
    return <>{children}</>;
  }
  

  const [isOpen, setIsOpen] = useState(false);
  const { editorState, setEditorState } = useEditorState();
  const frameData = useContext(FrameDataContext);
  // const { isEditable } = useContext(FrameDataContext);


  const handleContentChange = useCallback(
    (newValue: string) => {
      setEditorState((prevState) => {
        const updatedFrames = prevState.content.frames.map((frame) => {
        
          if (frame.id === frameData.id) {
            const contentKey = Object.keys(frame.content).find((key) =>
              isEqual(frame.content[key], content)
            );
            if (contentKey) {
              return {
                ...frame,
                content: {
                  ...frame.content,
                  [contentKey]: { ...content, value: newValue },
                },
              };
            }
          }
          return frame;
        });

        if (isEqual(updatedFrames, prevState.content.frames)) {
          return prevState;
        }

        return {
          ...prevState,
          content: {
            ...prevState.content,
            frames: updatedFrames,
          },
        };
      });
    },
    [frameData.id, content, setEditorState]
  );

  
  // if (!isEditable) { // // this gives error if we put this above handleContentChange and play Remotion player.
  //   return <>{children}</>;
  // }
  
  if (content.type === "text") {
    return (
      <FrameTextEditor
        content={content}
        isEditable={isEditable}
        handleContentChange={handleContentChange}
        style={style}
        hint={hint}
        offset={offset}
        className={className}
      >
        {children}
      </FrameTextEditor>
    );
  } else if (content.type === "video") {
    return (
      <FrameImageAndVideoUploader
        content={content}
        isEditable={isEditable}
        handleContentChange={handleContentChange}
        style={style}
        hint={hint}
        className={className}
      >
        {children}
      </FrameImageAndVideoUploader>
    );
  } else if (content.type === "image") {
    return (
      <FrameImageAndVideoUploader
        content={content}
        isEditable={isEditable}
        handleContentChange={handleContentChange}
        style={style}
        hint={hint}
        className={className}
      >
        {children}
      </FrameImageAndVideoUploader>
    );
  } else if (content.type === "list-text-only" || content.type === "list-image-only" || content.type === "list-text-with-image" || content.type === "list-text-with-secondary" || content.type === "list-text-with-secondary-and-image") {
    return (
      <ListEditorFactory
        listType={content.type}
        content={content}
        isEditable={isEditable}
        handleContentChange={handleContentChange}
        style={style}
        hint={hint}
        className={className}
      >
        {children}
      </ListEditorFactory>
    );
    // <FrameListEditor
    //     content={content}
    //     isEditable={isEditable}
    //     handleContentChange={handleContentChange}
    //     style={style}
    //     hint={hint}
    //   >
    //     {children}
    //   </FrameListEditor>
    // );
  // legacy list type. replaced with list-text-only, list-image-only, list-text-with-image, list-text-with-secondary, list-text-with-secondary-and-image
  } else if (content.type === "list") {
    return (
      <FrameListEditor
        content={content}
        isEditable={isEditable}
        handleContentChange={handleContentChange}
        style={style}
        hint={hint}
      >
        {children}
      </FrameListEditor>
    );
  } else {
    // text input as a fallback
    return (
      <FrameTextEditor
        content={content}
        isEditable={isEditable}
        handleContentChange={handleContentChange}
      >
        {children}
      </FrameTextEditor>
    );
  }
};

interface EditableFrameWrapperProps {
  frameData: any;
  isEditable: boolean;
  children: React.ReactNode;
}

export const EditableFrameWrapper: React.FC<EditableFrameWrapperProps> = ({
  frameData,
  isEditable,
  children,
}) => {
  // if(!isEditable){
  //   return (
  //       <>{children}</>
  //   );
  // }
  // else{
    return (
      <FrameDataContext.Provider value={{ ...frameData, isEditable }}>
        {children}
      </FrameDataContext.Provider>
    );
  // }
  // }else{
  //   return (
  //       <>{children}</>
  //   );
  // }
};
