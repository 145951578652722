import { Text } from '@mantine/core';
import { useEffect, useState } from 'react';

interface CountdownTimerProps {
  initialSeconds?: number;
}

export function CountdownTimer({ initialSeconds = 20 }: CountdownTimerProps) {
  const [timeLeft, setTimeLeft] = useState<string>('');

  useEffect(() => {
    // Generate random time between 10-15 seconds
    const seconds = Math.floor(Math.random() * 6) + initialSeconds;
    setTimeLeft(`00:${seconds.toString().padStart(2, '0')}:20`);

    const timer = setInterval(() => {
      setTimeLeft(current => {
        const [hours, secs, ms] = current.split(':');
        let newMs = parseInt(ms) - 10;
        let newSecs = parseInt(secs);
        
        if (newMs < 0) {
          newMs = 90;
          newSecs -= 1;
        }
        
        if (newSecs < 0) {
          clearInterval(timer);
          return 'almost done';
        }
        
        return `00:${newSecs.toString().padStart(2, '0')}:${newMs.toString().padStart(2, '0')}`;
      });
    }, 100);

    return () => clearInterval(timer);
  }, [initialSeconds]);

  return (
    <Text ff="JetBrains Mono, Menlo, monospace" style={{ minWidth: '80px' }}>
      {timeLeft}
    </Text>
  );
} 