export async function handleUpload(
    data: any,
    fileInputRef: React.RefObject<HTMLInputElement>,
    setUploadProgress: (progress: number) => void,
    onSuccess: (publicUrl: string) => void
  ) {
    if (data.success) {
      const file = fileInputRef.current?.files?.[0];
      if (file) {
        // Upload the file to R2 using the signed URL
        await fetch(data.signedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });
  
        // Simulate upload progress
        const totalSteps = 100;
        for (let i = 0; i <= totalSteps; i++) {
          setUploadProgress(i);
          await new Promise((resolve) => setTimeout(resolve, 20));
        }
  
        // Update the content with the public URL
        onSuccess(data.publicUrl);
      }
    } else {
      console.error("Failed to get signed URL");
    }
  }