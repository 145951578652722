import React, { useState, useRef, useEffect } from "react";
import {
  Box, Button, ActionIcon, Group, TextInput, Stack, Progress, Paper, ScrollArea, Popover, Text
} from "@mantine/core";
import { IconPlus, IconTrash, IconUpload, IconArrowUp, IconArrowDown, IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useFetcher } from "@remix-run/react";
import { useEditorState } from "../../../AppContent/state/EditorStateContext";
import { handleFileChange } from "../handleFileChange";
import { handleUpload } from "../handleUpload";

interface TextWithSecondaryAndImageItem {
  text: string;
  secondaryText: string;
  image: string;
}

interface TextWithSecondaryAndImageListEditorProps {
  content: {
    type: "list";
    value: TextWithSecondaryAndImageItem[];
  };
  isEditable: boolean;
  handleContentChange: (newValue: TextWithSecondaryAndImageItem[]) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string;
  className?: string;
}

export function TextWithSecondaryAndImageListEditor({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
  className,
}: TextWithSecondaryAndImageListEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState<TextWithSecondaryAndImageItem[]>(content.value);
  const [uploadingIndex, setUploadingIndex] = useState<number | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [hoveredImageIndex, setHoveredImageIndex] = useState<number | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const { editorState } = useEditorState();

  useEffect(() => {
    if (fetcher.data && fetcher.state === "idle" && uploadingIndex !== null) {
      handleUploadComplete(fetcher.data, uploadingIndex);
    }
  }, [fetcher]);

  const handleItemChange = (
    index: number,
    field: "text" | "secondaryText" | "image",
    value: string | null
  ) => {
    const updatedItems = [...listItems];
    if (value === null && field === "image") {
      const { image, ...rest } = updatedItems[index];
      updatedItems[index] = rest as TextWithSecondaryAndImageItem;
    } else {
      updatedItems[index] = { ...updatedItems[index], [field]: value };
    }
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleUploadComplete = async (data: any, index: number) => {
    if (data.success) {
      await handleUpload(data, fileInputRef, setUploadProgress, (publicUrl) => {
        handleItemChange(index, "image", publicUrl);
        setUploadingIndex(null);
        setUploadProgress(0);
      });
    } else {
      console.error("Failed to get signed URL");
    }
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadingIndex(index);
      handleFileChange({
        file,
        uploadType: "image",
        editorState,
        setUploadProgress,
        fetcher
      });
    }
  };

  const handleAddItem = () => {
    const newItem = { text: "", secondaryText: "", image: "" };
    setListItems([...listItems, newItem]);
    handleContentChange([...listItems, newItem]);
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = listItems.filter((_, i) => i !== index);
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleMoveItem = (index: number, direction: "up" | "down") => {
    const updatedItems = [...listItems];
    if (direction === "up" && index > 0) {
      [updatedItems[index - 1], updatedItems[index]] = [updatedItems[index], updatedItems[index - 1]];
    } else if (direction === "down" && index < updatedItems.length - 1) {
      [updatedItems[index], updatedItems[index + 1]] = [updatedItems[index + 1], updatedItems[index]];
    }
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleRemoveImage = (index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    handleItemChange(index, "image", null);
  };

  const initiateUpload = (index: number) => {
    if (fileInputRef.current) {
      fileInputRef.current.setAttribute('data-index', index.toString());
      fileInputRef.current.click();
    }
  };

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      onChange={setIsOpen}
      width={400}
      position="left"
      offset={20}
      withArrow
    >
      <Popover.Target>
        <Box className={`frame__editable_content ${className}`} onClick={() => setIsOpen((o) => !o)} style={style}>
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea.Autosize mah={800}>
          {hint && (
            <Text size="sm" c="dimmed" mb="xs">
              {hint}
            </Text>
          )}
          <div style={{paddingRight: "16px"}}>
          {listItems.map((item, index) => (
            <Box key={index} style={{ marginBottom: "50px" }}>
              <Group align="flex-start" wrap="nowrap">
                <Stack style={{ flex: 1 }} gap="xs">
                  <TextInput
                    classNames={{ input: "frame__editable_content_text_input" }}
                    placeholder={t("Enter main text")}
                    value={item.text}
                    onChange={(e) => handleItemChange(index, "text", e.target.value)}
                    rightSection={
                      <ActionIcon.Group mr="xl">
                        <ActionIcon
                          variant="transparent"
                          color="gray"
                          onClick={() => handleMoveItem(index, "up")}
                          disabled={index === 0}
                          title={t("Move up")}
                        >
                          <IconArrowUp size="1rem" />
                        </ActionIcon>
                        <ActionIcon
                          variant="transparent"
                          color="gray"
                          onClick={() => handleMoveItem(index, "down")}
                          disabled={index === listItems.length - 1}
                          title={t("Move down")}
                        >
                          <IconArrowDown size="1rem" />
                        </ActionIcon>
                      </ActionIcon.Group>
                    }
                  />
                  <TextInput
                    placeholder={t("Enter secondary text")}
                    value={item.secondaryText}
                    onChange={(e) => handleItemChange(index, "secondaryText", e.target.value)}
                  />
                  {item.image ? (
                    <Box
                      mt="xs"
                      style={{ position: 'relative' }}
                      onMouseEnter={() => setHoveredImageIndex(index)}
                      onMouseLeave={() => setHoveredImageIndex(null)}
                    >
                      <Paper
                        p="sm"
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                          overflow: 'hidden',
                          borderRadius: '4px',
                          textAlign: "center"
                        }}
                        onClick={() => initiateUpload(index)}
                      >
                        <img
                          src={item.image}
                          alt=""
                          style={{
                            display: 'block',
                            margin: "auto",
                            maxHeight: "50px",
                          }}
                        />
                        {hoveredImageIndex === index && (
                          <>
                            <Box
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(0, 0, 0, 0.4)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <IconUpload size="2rem" color="white" />
                            </Box>
                            <ActionIcon
                              variant="light"
                              color="red"
                              style={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                zIndex: 2
                              }}
                              onClick={(e) => handleRemoveImage(index, e)}
                              title={t("Remove image")}
                            >
                              <IconX size="1rem" />
                            </ActionIcon>
                          </>
                        )}
                      </Paper>
                    </Box>
                  ) : (
                    <Button
                      onClick={() => initiateUpload(index)}
                      leftSection={<IconUpload size="1rem" />}
                      variant="light"
                      // size="compact-sm"
                      // pt="xs"
                      disabled={uploadingIndex !== null}
                    >
                      {t("Add logo")}
                    </Button>
                  )}
                  {uploadingIndex === index && (
                    <Progress value={uploadProgress} size="sm" />
                  )}
                </Stack>
                <ActionIcon
                  variant="light"
                  color="red"
                  onClick={() => handleRemoveItem(index)}
                  title={t("Remove item")}
                >
                  <IconTrash size="1rem" />
                </ActionIcon>
              </Group>
            </Box>
          ))}
          <Button
            variant="light"
            leftSection={<IconPlus size="1rem" />}
            onClick={handleAddItem}
            fullWidth
            mt="md"
          >
            {t("Add item")}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(e) => {
              const index = parseInt(fileInputRef.current?.getAttribute('data-index') || '0', 10);
              onFileChange(e, index);
            }}
          />
          </div>
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
}
