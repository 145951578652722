// app/components/remotionHelpers/Frames/FramesComponents/Frame1/USPtext.tsx

import React, { useMemo } from "react";
import { interpolate, spring } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { calculateFontSize } from "../../../remotionHelpers/calculateFontSize";
import { FPS } from "../../../remotionConstant";

const ANIMATION_DELAY_FRAMES = 10;

export const USPtext = ({
  uspText,
  currentRemotionFrame,
  durationInFrames,
  displayAnimations,
  isEditable,
  commonStyles,
}) => {
  const progress = spring({
    fps: FPS,
    frame: Math.max(0, currentRemotionFrame - ANIMATION_DELAY_FRAMES),
    config: {
      damping: 200,
    },
    durationInFrames: 100,
  });

  const rightStop = interpolate(progress, [0, 1], [200, 0]);
  const leftStop = Math.max(0, rightStop - 60);
  const maskImage = `linear-gradient(-45deg, transparent ${leftStop}%, black ${rightStop}%)`;
  const opacity = interpolate(
    Math.max(0, currentRemotionFrame - ANIMATION_DELAY_FRAMES),
    [0, 30],
    [0, 1],
    { extrapolateLeft: "clamp", extrapolateRight: "clamp" }
  );

  const containerStyle = useMemo(
    () => ({
      // position: "absolute" as const,
      // top: "20em",
      // left: "6.1em",
      // maxWidth: "41em",
    }),
    []
  );

  const contentStyle = useMemo(
    () => ({
      opacity: displayAnimations ? opacity : 1,
      maskImage: displayAnimations ? maskImage : undefined,
      WebkitMaskImage: displayAnimations ? maskImage : undefined,
    }),
    [displayAnimations, opacity, maskImage]
  );

  const fontSize = calculateFontSize(
    uspText?.value?.length || 0,
    [12, 22, 35, 60, 75], // referenceLengths
    [5, 4.5, 4, 3.5, 3] // fontSizes (max to min)
  );

  const textStyle = useMemo(
    () => ({
      ...commonStyles,
      fontSize: `${fontSize}em`,
      fontWeight: 400,
      letterSpacing: "0.02em",
      lineHeight: 1.4,
    }),
    [commonStyles, fontSize]
  );

  const showText = () => (
    <span style={textStyle}>{uspText?.value || "Default USP Text"}</span>
  );

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        {isEditable ? (
          <Editable
            isEditable={isEditable}
            content={uspText}
            hint="Describe your project in a few words."
            className="frame__editable_content--normal-size"
          >
            {showText()}
          </Editable>
        ) : (
          showText()
        )}
      </div>
    </div>
  );
};