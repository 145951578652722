import { useFetcher, useNavigate } from "@remix-run/react";
import { useRouteLoaderData } from "@remix-run/react";
import { getRoute } from "~/routes";
import { RootLoaderData } from "~/root";
import { useEffect } from "react";

export function useCreateNewVideo({
  content,
  title,
  thumbnail,
}: {
  content?: any[];
  title?: string;
  thumbnail?: string;
} = {}) {
  const data = useRouteLoaderData("root") as RootLoaderData;
  const { projects } = data;
  const defaultProject = projects[0];
  
  const fetcher = useFetcher();
  const navigate = useNavigate();

  useEffect(() => {
    if (fetcher.data && fetcher.data.success) {
      navigate(
        `${getRoute("video", {
          video_slug: fetcher.data.video.slug,
        })}?skipLoader=true`,
        {
          state: { video: fetcher.data.video },
          replace: false, // we don't want to replace the current history state
        }
      );
    }
  }, [fetcher.data, navigate]);

  const createVideoObjectInDB = ({
    content,
    title,
    thumbnail,
  }: {
    content?: any;
    title?: string;
    thumbnail?: string;
  } = {}) => {
    const defaultProjectStyle = {
      accentColor: defaultProject.accentColor,
      font: defaultProject.font,
      backgroundColorID: defaultProject.backgroundColorID,
    };
    
    // if content is not provided, we create an empty video frame set
    const videoContent = content || {
      frames: [],
    };
    
    const submitData: Record<string, string> = {
      title: title || "New video",
      projectId: defaultProject.id,
      style: JSON.stringify(defaultProjectStyle),
      content: JSON.stringify(videoContent),
    };

    if (thumbnail) {
      submitData.thumbnailUrl = thumbnail;
    }
    
    fetcher.submit(
      submitData,
      { method: "post", action: getRoute("createVideo") }
    );
  };

  return {
    createVideoObjectInDB,
    isLoadingCreatingVideo: fetcher.state === "submitting"
  };
} 