import { Box, Button, Text } from '@mantine/core';
import { IconMaximize } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";
import { useNavigation } from "react-router";
import { triggerAnalyticsEvent, AnalyticsEventTypes } from '../../../utils/analytics';

interface BlankVideoFormProps {
  onClickCreateBlankVideo: () => void;
  isLoadingCreatingBlankVideo: boolean;
}

export function BlankVideoForm({ onClickCreateBlankVideo, isLoadingCreatingBlankVideo }: BlankVideoFormProps) {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const onCreateBlank = async () => {
    try {
      triggerAnalyticsEvent(AnalyticsEventTypes.CREATE_NEW_BLANK_VIDEO);
      await onClickCreateBlankVideo();
    } catch (error) {
      triggerAnalyticsEvent(AnalyticsEventTypes.CREATE_NEW_BLANK_VIDEO_ERROR, {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  };

  return (
    <Box>
      <Text size="sm" fw={600} mb={8}>
        {t('Create blank video')}
      </Text>
      <Text size="sm" c="dimmed" mb="md">
        {t('Start with an empty video project')}
      </Text>
      <Button
        onClick={onCreateBlank}
        loading={isLoadingCreatingBlankVideo || navigation.state === "loading"}
        variant="light"
        size="md"
        fullWidth
        rightSection={<IconMaximize size={"16px"} />}
      >
        {t('Create empty video')}
      </Button>
    </Box>
  );
} 