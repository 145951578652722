import { Timeline, Text, Loader } from '@mantine/core';
import { IconWorld, IconRobot, IconVideo, IconCheck, IconScan } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";

interface UrlToVideoProcessTimelineProps {
  activeStep: number;
}

export function UrlToVideoProcessTimeline({ activeStep }: UrlToVideoProcessTimelineProps) {
  const { t } = useTranslation();

  return (
    <Timeline active={activeStep} bulletSize={24} lineWidth={2}>
      <Timeline.Item
        bullet={<IconScan size={12} />}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('Initializing')}
            {activeStep === 0 && <Loader type="dots" color="var(--mantine-color-dimmed)" size="xs" style={{ marginLeft: 8 }} />}
          </div>
        }
      >
        <Text c="dimmed" size="sm">
          {t('Checking the website URL.')}
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconWorld size={12} />}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('Website Analysis')}
            {activeStep === 1 && <Loader type="dots" color="var(--mantine-color-dimmed)" size="xs" style={{ marginLeft: 8 }} />}
          </div>
        }
      >
        <Text c="dimmed" size="sm">
        {t('Getting the page text and images.')}
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconRobot size={12} />}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('AI Processing')}
            {activeStep === 2 && <Loader type="dots" color="var(--mantine-color-dimmed)" size="xs" style={{ marginLeft: 8 }} />}
          </div>
        }
      >
        <Text c="dimmed" size="sm">
          {t('Creating a video with visuals and narration.')}
        </Text>
      </Timeline.Item>

      <Timeline.Item
        bullet={<IconCheck size={12} />}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('Redirecting to your video')}
            {activeStep === 3 && <Loader type="dots" color="var(--mantine-color-dimmed)" size="xs" style={{ marginLeft: 8 }} />}
          </div>
        }
      >
        <Text c="dimmed" size="sm">
          {t('Your video is ready to be customized and downloaded!')}
        </Text>
      </Timeline.Item>
    </Timeline>
  );
} 