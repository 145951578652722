import React, { Component, ErrorInfo } from 'react';
import { Alert, Button, Stack } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

// Wrapper component to use hooks with class component
function ErrorAlert({ error, onReset }: { error: Error | null; onReset: () => void }) {
  const { t } = useTranslation();
  
  return (
    <Stack>
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title={t("Something went wrong")}
        color="red"
      >
        {error?.message || t("An unexpected error occurred while editing the list")}
      </Alert>
      <Button variant="light" onClick={onReset}>
        {t("Try again")}
      </Button>
    </Stack>
  );
}

export class ListEditorErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('List Editor Error:', error, errorInfo);
  }

  handleReset = () => {
    this.setState({
      hasError: false,
      error: null
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorAlert 
          error={this.state.error} 
          onReset={this.handleReset}
        />
      );
    }

    return this.props.children;
  }
}

// HOC to wrap list editors with error boundary
export function withListEditorErrorBoundary<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  return function WithErrorBoundary(props: P) {
    return (
      <ListEditorErrorBoundary>
        <WrappedComponent {...props} />
      </ListEditorErrorBoundary>
    );
  };
} 