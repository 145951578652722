// Domain validation utility
export function validateAndFormatUrl(url: string): string | null {
  try {
    // Add protocol if missing
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }

    const urlObject = new URL(url);
    
    // Ensure it has a valid hostname
    if (!urlObject.hostname.includes('.')) {
      return null;
    }

    return urlObject.toString();
  } catch {
    return null;
  }
}