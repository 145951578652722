import { Modal, Button, Group, TextInput, Stack, Text, Box, Divider, Chip, Badge } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { useNavigation } from "react-router";
import { useCreateNewVideo } from "./useCreateNewVideo";
import { useState, useEffect } from "react";
import { IconClick, IconMaximize, IconSparkles, IconWand } from '@tabler/icons-react';
import { UrlToVideoForm } from './UrlToVideoForm';
import { BlankVideoForm } from './BlankVideoForm';

interface NewVideoModalProps {
  opened: boolean;
  onClose: () => void;
}

export function NewVideoModal({ opened, onClose }: NewVideoModalProps) {
  const { t } = useTranslation();
  const { createVideoObjectInDB, isLoadingCreatingVideo } = useCreateNewVideo({title: "Blank video"});
  const [isProcessingUrlToVideo, setIsProcessingUrlToVideo] = useState(false);

  // reset the isProcessingUrlToVideo state when the modal is closed
  useEffect(() => {
    if (!opened && isProcessingUrlToVideo) {
      setIsProcessingUrlToVideo(false);
    }
  }, [opened]);

  // const handleClose = () => {
  //   setIsProcessingUrlToVideo(false);
  //   onClose();
  // };

  return (
    <Modal 
      opened={opened} 
      onClose={onClose}
      title={t('Create New Video')}
      size="lg"
      padding="lg"
    >
      <Stack gap="xl">
          <>
            <UrlToVideoForm 
              // we need to pass the isLoadingCreatingVideo from the useCreateNewVideo hook
              // to hide the "create blank video" button when the url to video form is processing
              isLoadingCreatingBlankVideo={isLoadingCreatingVideo}
              setIsProcessingUrlToVideo={setIsProcessingUrlToVideo}
              isProcessingUrlToVideo={isProcessingUrlToVideo}
            />

            {!isProcessingUrlToVideo && (
              <>
                <Divider label={t('or')} labelPosition="center" />
                
                <BlankVideoForm 
                  onClickCreateBlankVideo={() => createVideoObjectInDB({title: "Blank video"})}
                  isLoadingCreatingBlankVideo={isLoadingCreatingVideo}
                />

                {/* Footer */}
                <Group justify="flex-end">
                  <Button variant="subtle" onClick={onClose}>
                    {t('Cancel')}
                  </Button>
                </Group>
              </>
            )}
          </>
      </Stack>
    </Modal>
  );
}