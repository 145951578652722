import React from "react";
import { ImageListEditor } from "./ImageListEditor";
import { TextWithImageListEditor } from "./TextWithImageListEditor";
import { TextWithSecondaryListEditor } from "./TextWithSecondaryListEditor";
import { TextOnlyListEditor } from "./TextOnlyListEditor";
import { TextWithSecondaryAndImageListEditor } from "./TextWithSecondaryAndImageListEditor";
import { withListEditorErrorBoundary } from './ListEditorErrorBoundary';
import { FrameListEditor } from "../FrameListEditor";
// Wrap each editor component with error boundary
const SafeImageListEditor = withListEditorErrorBoundary(ImageListEditor);
const SafeTextWithImageListEditor = withListEditorErrorBoundary(TextWithImageListEditor);
const SafeTextWithSecondaryListEditor = withListEditorErrorBoundary(TextWithSecondaryListEditor);
const SafeTextWithSecondaryAndImageListEditor = withListEditorErrorBoundary(TextWithSecondaryAndImageListEditor);
const SafeTextOnlyListEditor = withListEditorErrorBoundary(TextOnlyListEditor);

// this is a legacy editor that is not used anymore (generic ListEditor was split into multiple single-purpose components)
// the generic type is "list"
const SafeFrameListEditor = withListEditorErrorBoundary(FrameListEditor);

export function ListEditorFactory(props) {
  switch (props.listType) {
    case 'list-text-only':
      return <SafeTextOnlyListEditor {...props} />;
    case 'list-image-only':
      return <SafeImageListEditor {...props} />;
    case 'list-text-with-image':
      return <SafeTextWithImageListEditor {...props} />;
    case 'list-text-with-secondary':
      return <SafeTextWithSecondaryListEditor {...props} />;
    case 'list-text-with-secondary-and-image':
      return <SafeTextWithSecondaryAndImageListEditor {...props} />;
    case 'list':
      return <SafeFrameListEditor {...props} />;
    //   if you want to add a new list type, add it also in EditableFrameWrapper.tsx
    default:
      return <SafeTextOnlyListEditor {...props} />;
  }
}