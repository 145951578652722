import { validateAndFormatUrl } from './validateAndFormatUrl';

// Handles various URL formats:
// https://my-startup.com → my-startup
// https://app.my-startup.com → app-my-startup
// my-startup.com → my-startup
export function getDomainWithoutTLD(url: string): string {
  try {
    // First validate and format the URL
    const formattedUrl = validateAndFormatUrl(url);
    if (!formattedUrl) {
      return url;
    }

    // Get hostname from URL
    const hostname = new URL(formattedUrl).hostname;
    
    // Split by dots and take everything except the last part (TLD)
    // Join with hyphens to create a clean string
    return hostname.split('.').slice(0, -1).join('-');
  } catch {
    // Fallback: just return the first part before any dots
    return url.split('.')[0];
  }
} 