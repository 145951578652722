import { Easing } from "remotion";

export const easeInOutExpo = Easing.bezier(0.87, 0, 0.13, 1);
export const FPS = 30;
export const COMPOSITION_NAME = "paracast2";

export const RENDERED_VIDEO_FILE_NAME = "video-paracast";
export const MAKER_PLAN_TITLE = "maker";

export const TRANSITION_DURATION_IN_FRAMES = 10;

export const VIDEO_ERROR_SRC = "https://public-assets.paracast.io/video-error.mp4";