import { TextInput, Button, Group, Text, Box, Badge, Loader, ActionIcon, Tooltip } from '@mantine/core';
import { IconWand, IconSparkles, IconArrowsShuffle } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";
import { useNavigation } from "react-router";
import { useState, useEffect } from "react";
import { UrlToVideoProcessTimeline } from "./UrlToVideoProcessTimeline";
import { useFetcher, useNavigate, useRouteLoaderData } from "@remix-run/react";
import { CountdownTimer } from "./CountdownTimer";
import { getRoute } from "~/routes";
import { validateAndFormatUrl } from '~/utils/validateAndFormatUrl';
import { constants } from '~/constants';
import { getFaviconUrl } from '~/utils/getFaviconUrl';
import { RootLoaderData } from '~/root';
import { useCreateNewVideo } from './useCreateNewVideo';
import { getDomainWithoutTLD } from '~/utils/getDomainWithoutTLD';
import pkg from 'lodash';
import { ProcessUrlResponse } from '~/routes/api.url-to-video.process';
import { ParseUrlResponse } from '~/routes/api.url-to-video.parse';
import { AnalyticsEventTypes, triggerAnalyticsEvent } from '~/utils/analytics';
const {capitalize} = pkg;


interface UrlToVideoFormProps {
  isLoadingCreatingBlankVideo: boolean;
  setIsProcessingUrlToVideo: (value: boolean) => void;
  isProcessingUrlToVideo: boolean;
}

export function UrlToVideoForm({ isLoadingCreatingBlankVideo, setIsProcessingUrlToVideo, isProcessingUrlToVideo }: UrlToVideoFormProps) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [url, setUrl] = useState('');

  const [currentStep, setCurrentStep] = useState(0);
  const parseFetcher = useFetcher<ParseUrlResponse>();
  const processFetcher = useFetcher<ProcessUrlResponse>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [faviconUrl, setFaviconUrl] = useState<string | null>(null);


  const { createVideoObjectInDB, isLoadingCreatingVideo } = useCreateNewVideo();
  const handleSubmitUrlToVideoForm = async () => {
    setError(undefined);
    
    // Validate URL before submitting
    const validatedUrl = validateAndFormatUrl(url);
    if(url.length === 0) {
      setError("Please enter a page URL.");
      return;
    }else if (!validatedUrl) {
      setError("Please re-check the URL. It is invalid.");
      return;
    }

    setIsProcessingUrlToVideo(true);
    setCurrentStep(1);

    parseFetcher.submit(
      { url: validatedUrl },
      { method: "post", action: getRoute("parseUrl") }
    );
  };

  useEffect(() => {
    if (parseFetcher.data?.error || processFetcher.data?.error) {
      const errorMessage = parseFetcher.data?.error || processFetcher.data?.error;
      setError(errorMessage);
      setIsProcessingUrlToVideo(false);
      setCurrentStep(0);
    }
  }, [parseFetcher.data, processFetcher.data]);

  useEffect(() => {
    if (parseFetcher.data?.success) {
      setCurrentStep(2);
      processFetcher.submit(
        { parsedDataObject: JSON.stringify(parseFetcher.data) },
        { method: "post", action: getRoute("processUrl") }
      );
    }
  }, [parseFetcher.data]);

  useEffect(() => {
    if (processFetcher.data?.success) {
      setCurrentStep(3);

      createVideoObjectInDB({
        content: processFetcher.data.framesSequenceWithFinalContent, 
        title: `${capitalize(getDomainWithoutTLD(url))} showcase`,
        thumbnail: parseFetcher.data.openGraphImage,
      });
    }
  }, [processFetcher.data]);

  useEffect(() => {
    const urlFavicon = getFaviconUrl(url);
    setFaviconUrl(urlFavicon);
  }, [url]);

  return (
    <Box>
      <Group mb={8}>
        <Text size="sm" fw={600}>
          Web page → video
        </Text>
        <Badge variant="outline" color="blue" size="md">
          AI
        </Badge>
      </Group>
      <Text size="sm" c="dimmed" mb="md">
        {t('Enter your project\'s URL to convert the site into a video.')}
      </Text>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmitUrlToVideoForm();
      }}>
        <Group gap="sm" align="top" grow mb={isProcessingUrlToVideo ? "xl" : 0}>
          <TextInput
            placeholder={'https://domain.com'}
            value={url}
            onChange={(e) => setUrl(e.currentTarget.value)}
            size="md"
            disabled={isProcessingUrlToVideo && !error}
            error={error}
            leftSection={faviconUrl ? <img src={faviconUrl} alt="Favicon" style={{ width: 14, height: 14, borderRadius: 2 }} /> : null}
            rightSection={
              <Tooltip position="bottom-end" withArrow arrowSize={10} label="Fill with a random URL">
                <ActionIcon 
                  variant="light" 
                  size="md" 
                  onClick={() => {
                    const availableProjects = constants.MARSX_PROJECTS.filter(
                      project => project !== url
                    );
                    const randomProject = availableProjects[
                      Math.floor(Math.random() * availableProjects.length)
                    ];
                    triggerAnalyticsEvent(AnalyticsEventTypes.URL_TO_VIDEO_RANDOM_URL, { 
                      previousUrl: url,
                      newUrl: randomProject 
                    });
                    setUrl(randomProject);
                  }}
                  disabled={isProcessingUrlToVideo && !error}
                  aria-label="Fill with demo URL"
                >
                  <IconArrowsShuffle size="16px" />
                </ActionIcon>
              </Tooltip>
            }
          />
          <Button
            type="submit"
            // loading={navigation.state === "loading"}
            // disabled={!url.trim() || (isProcessingUrlToVideo && !error) || isLoadingCreatingBlankVideo || navigation.state === "loading"}
            disabled={(isProcessingUrlToVideo && !error) || isLoadingCreatingBlankVideo || navigation.state === "loading"}
            size="md"
            rightSection={isProcessingUrlToVideo && !error ? <Loader color="var(--mantine-color-dimmed)" size="xs" /> : <IconSparkles size="16px" />}
          >
            {isProcessingUrlToVideo && !error ? (
              <CountdownTimer initialSeconds={20} />
            ) : t('Create from URL')}
          </Button>
        </Group>
      </form>
      
      {isProcessingUrlToVideo && !error && (
        <UrlToVideoProcessTimeline activeStep={currentStep} />
      )}
    </Box>
  );
} 