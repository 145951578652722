import React, { useState } from "react";
import {
  Box, Button, ActionIcon, Group, TextInput, Stack, ScrollArea, Popover
} from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface TextWithSecondaryItem {
  text: string;
  secondaryText: string;
}

interface TextWithSecondaryListEditorProps {
  content: {
    type: "list";
    value: TextWithSecondaryItem[];
  };
  isEditable: boolean;
  handleContentChange: (newValue: TextWithSecondaryItem[]) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string;
}

export function TextWithSecondaryListEditor({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
}: TextWithSecondaryListEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState<TextWithSecondaryItem[]>(content.value);
  const { t } = useTranslation();

  const handleTextChange = (index: number, field: "text" | "secondaryText", value: string) => {
    const updatedItems = [...listItems];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleAddItem = () => {
    setListItems([...listItems, { text: "", secondaryText: "" }]);
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = listItems.filter((_, i) => i !== index);
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      onChange={setIsOpen}
      width={400}
      position="left"
      offset={20}
      withArrow
    >
      <Popover.Target>
        <Box onClick={() => setIsOpen((o) => !o)} style={style}>
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea.Autosize mah={800}>
          {listItems.map((item, index) => (
            <Box key={index} mb="xl">
              <Stack spacing="xs">
                <TextInput
                  value={item.text}
                  onChange={(e) => handleTextChange(index, "text", e.target.value)}
                  placeholder={t("Enter main text")}
                />
                <TextInput
                  value={item.secondaryText}
                  onChange={(e) => handleTextChange(index, "secondaryText", e.target.value)}
                  placeholder={t("Enter secondary text")}
                />
                <ActionIcon
                  variant="light"
                  color="red"
                  onClick={() => handleRemoveItem(index)}
                >
                  <IconTrash size="1rem" />
                </ActionIcon>
              </Stack>
            </Box>
          ))}
          <Button
            variant="light"
            leftSection={<IconPlus size="1rem" />}
            onClick={handleAddItem}
            fullWidth
          >
            {t("Add item")}
          </Button>
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
} 